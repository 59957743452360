import dynamic from "next/dynamic";
import React, {ReactNode, useEffect, useState} from "react";
import {IProps} from "../types/homePageTypes";
import {PropsConsumer} from "../context";
import {useRouter} from "next/router";
import {setRoomId, setWindowSize} from "../store/general";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../types/state";

const HotelTopBar = dynamic(() => import("./header/Hotel_TopBar"))
const HotelFooter = dynamic(() => import("./footer/Hotel_Footer"))
const HotelHeader = dynamic(() => import("./header"))
const MobileMenu = dynamic(() => import("./mobile/MobileMenu"))
const HotelDepartmentMenu = dynamic(() => import("./header/Hotel_DepartmentMenu"))
const MobileFooter = dynamic(() => import("./footer/MobileFooter"))
const MobileHeader = dynamic(() => import("./mobile/MobileHeader"))

const MainContent = ({children}: { children: ReactNode }) => {
    const dispatch = useDispatch()
    const router = useRouter()
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    const [isTablet, setIsTablet] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const {query} = router

        if (query.room_id) {
            sessionStorage.setItem("roomID", query.room_id as string)
        }
    }, [])

    useEffect(() => {

        const windowResizeEvent = () => {
            dispatch(setWindowSize(window.innerWidth))
        }

        window.addEventListener("resize", windowResizeEvent)

        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsTablet(windowSize <= 991)
        setIsMobile(windowSize <= 768)

        return () => {
            window.removeEventListener("resize", windowResizeEvent)
        }
    }, [windowSize]);

    const Headers = isTablet
        // const Headers = true
        ? <>
            <HotelTopBar/>
            <div className="m_headerWrapper">
                <MobileHeader/>
                <HotelDepartmentMenu/>
            </div>
        </>
        :
        <>
            <HotelTopBar/>
            <div className="d_headerWrapper">
                <HotelHeader/>
                <HotelDepartmentMenu/>
            </div>
        </>


    // const Footers = isMobile
    //     ? <MobileFooter/>
    //     : (
    //         <PropsConsumer>
    //             {
    //                 props => {
    //                     return (<HotelFooter pageProps={props as IProps}/>)
    //                 }
    //             }
    //         </PropsConsumer>
    //     )

    const Footers = (
        <PropsConsumer>
            {
                props => {
                    return (<HotelFooter pageProps={props as IProps}/>)
                }
            }
        </PropsConsumer>
    )

    return (
        <div>
            {Headers}
            {children}
            {Footers}
        </div>
    )
}

export default MainContent;